.phone-input-search {
    padding: 0.5rem !important;

    .phone-input-search-box {
        display: block;
        width: 100%;
        padding: 0.25rem 0.5rem;
        font-size: 0.7109375rem;
        margin-left: 0 !important;
        font-weight: 400;
        line-height: 1.5;
        color: var(--body-color);
        appearance: none;
        background-color: var(--input-bg-custom);
        background-clip: padding-box;
        border: var(--border-width) solid var(--input-border-custom);
        border-radius: var(--border-radius-sm);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        min-height: calc(1.5em + 0.5rem + calc(var(--border-width)* 2));
    }
}