.auto-hide-actions {
    display: none;
}

.area-item {
    --area-color: white;
    --area-bg-color: color-mix(in srgb, var(--area-color) 75%, var(--body-bg) 25%);
    --area-bg-subtle-color: color-mix(in srgb, var(--area-color) 25%, var(--body-bg) 75%);
    aspect-ratio: 3 / 1;
    background-color: var(--area-bg-color);

    .area-name {
        h5 {
            background-color: var(--area-bg-subtle-color);
        }

        small {
            background-color: var(--area-bg-subtle-color);
        }
    }

    .btn {
        font-size: $font-size-lg;

        i {
            text-shadow: 0.05rem 0 var(--area-bg-subtle-color), 0 0.05rem var(--area-bg-subtle-color), -0.05rem 0 var(--area-bg-subtle-color), 0 -0.05rem var(--area-bg-subtle-color), 
                -0.05rem -0.05rem var(--area-bg-subtle-color), -0.05rem 0.05rem var(--area-bg-subtle-color), 0.05rem -0.05rem var(--area-bg-subtle-color), 0.05rem 0.05rem var(--area-bg-subtle-color);
        }
    }

    .area-attributes {
        span {
            background-color: var(--area-bg-subtle-color);
        }
    }

    &:hover .auto-hide-actions {
        display: block;
    }
}

.aisle-item {
    .bays {
        overflow-x: scroll;

        .bay-item {
            width: 15%;

            .card {
                height: 4rem;
            }
        }
    }

    &:hover .aisle-actions {
        display: block;
    }
}

.bay-item {
    &:hover .bay-actions {
        display: block;
    }
}

.shelf-item {
    &:hover .shelf-actions {
        display: block;
    }

    .shelf-bins {
        .bin-item {
            padding: 1rem;
        }
    }
}

.shelves {
    overflow-x: scroll;

    .shelf-grid {
        width: 15%;

        .shelf-item {

            .shelf-bins {
                .bin-item {
                    font-size: $font-size-sm;
                }
            }
        }
    }
}

.bin-item {
    &:hover .bin-actions {
        display: block;
    }
}