.product-list-image {
    width: 3.3rem;
    margin: -0.4rem 0;
    aspect-ratio: 1;
    object-fit: scale-down;
}

.product-list-image-large {
    width: 300px;
    aspect-ratio: 1;
    object-fit: scale-down;
}

.product-image-upload-display {
    aspect-ratio: 1;
    border: var(--border-width) var(--border-style) var(--border-color) !important;
    border-color: var(--dark-border-subtle) !important;

    & div {
        width: 100%;
        height: 100%;
        position: absolute;

        &:hover a {
            visibility: visible;
        }

        & a {
            visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease;
            height: 100%;
            
            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }
}