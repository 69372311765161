@import "./config/default/variables";
/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.blur-1 {
    filter: blur($border-radius);
}

.blur-2 {
    filter: blur($border-radius * 2);
}

.blur-3 {
    filter: blur($border-radius * 4);
}

.w-10 {
    width: 10%;
}

.aspect-1x1 {
    aspect-ratio: 1;
}

.aspect-1x2 {
    aspect-ratio: 1 / 2;
}

.label-separator {
    position: relative;
    --#{$prefix}size: 1;
    --#{$prefix}style: solid;
    text-align: center;

    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: calc(1px * var(--#{$prefix}size));
        left: 0;
        right: 0;
        border-top-width: calc(1px * var(--#{$prefix}size));
        border-top-style: var(--#{$prefix}style);
        border-top-color: var(--#{$prefix}border-color);
        top: 10px;
    }

    .label {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: var(--#{$prefix}secondary-bg);
        padding: 2px 16px;
    }
}

.label-separator-dashed {
    --#{$prefix}style: dashed;
}

.label-seperator-2 {
    --#{$prefix}size: 2;
}

.label-seperator-3 {
    --#{$prefix}size: 4;
}

.filepond--root {
    margin-bottom: 0 !important;
}

:root {
    --btn-disabled-border-color: transparent;
}

.btn {
    --btn-disabled-opacity: 0.35;
}

.input-group .form-control.is-valid ~ .input-group-text  {
    border-color: var(--form-valid-border-color);
}

.input-group .form-control.is-invalid ~ .input-group-text  {
    border-color: var(--form-invalid-border-color);
}

.input-group .form-control ~ .input-group-text  {
    background-color: var(--input-bg-custom);
}

.input-group .form-control:disabled ~ .input-group-text  {
    background-color: var(--tertiary-bg);
}

.text-truncate {
    --line-count: 1;  /* number of lines to show */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--line-count);
    line-clamp: var(--line-count);
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    &.text-truncate-2 {
        --line-count: 2;
    }

    &.text-truncate-3 {
        --line-count: 3;
    }

    &.text-truncate-4 {
        --line-count: 4;
    }

    &.text-truncate-5 {
        --line-count: 5;
    }
}

.text-decoration-dotted-underline {
    text-decoration: underline dotted;
    text-underline-offset: 2px;
}

.animated-rotation {
    animation: full-rotation 1.2s infinite linear;
}

@keyframes full-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// width classes for 10% to 100%
$widths: 10 20 30 40 50 60 70 80 90 100;

// loop through width array
@each $width in $widths {
    .w-#{$width} {
        width: #{$width + '%'};
    }
}

.dropdown-toggle::after {
    margin-left: 0;
}