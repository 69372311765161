:root {
    --tenant-primary-color: #{$primary};
    --tenant-secondary-color: #{$secondary};
    --tenant-cover-background: none;
}

.navbar-menu .navbar-nav .nav-link.active {
    color: var(--tenant-primary-color);
    background-color: color-mix(in srgb, var(--tenant-primary-color) 10%, white);
}

.navbar-menu .navbar-nav .nav-sm .nav-link.active {
    color: var(--tenant-secondary-color);
    background-color: transparent;
}

.bg-tenant-primary {
    background-color: var(--tenant-primary-color);
}

.bg-tenant-primary-subtle {
    background-color: color-mix(in srgb, var(--tenant-primary-color) 25%, white);
}

.bg-tenant-secondary {
    background-color: var(--tenant-secondary-color);
}

.bg-tenant-secondary-subtle {
    background-color: color-mix(in srgb, var(--tenant-secondary-color) 25%, white);
}

.text-tenant-primary {
    color: var(--tenant-primary-color);
}

.text-tenant-primary-subtle {
    color: color-mix(in srgb, var(--tenant-primary-color) 25%, white);
}

.text-tenant-secondary {
    color: var(--tenant-secondary-color);
}

.text-tenant-secondary-subtle {
    color: color-mix(in srgb, var(--tenant-secondary-color) 25%, white);
}

.tenant-cover-background {
    background-image: var(--tenant-cover-background);
}

.table-tenant-primary {
    --table-color: var(--tenant-primary-color);
    --table-bg: color-mix(in srgb, var(--tenant-primary-color) 25%, white);
    --table-border-color: var(--tenant-primary-color);
    --table-striped-bg: color-mix(in srgb, var(--tenant-primary-color) 15%, white);
    --table-striped-color:var(--tenant-primary-color);
    --table-active-bg: color-mix(in srgb, var(--tenant-primary-color) 10%, white);
    --table-active-color: var(--tenant-primary-color);
    --table-hover-bg: color-mix(in srgb, var(--tenant-primary-color) 10%, white);
    --table-hover-color: var(--tenant-primary-color);
    color: var(--table-color);
    border-color: var(--table-border-color);
}

.table-tenant-secondary {
    --table-color: var(--tenant-secondary-color);
    --table-bg: color-mix(in srgb, var(--tenant-secondary-color) 25%, white);
    --table-border-color: var(--tenant-secondary-color);
    --table-striped-bg: color-mix(in srgb, var(--tenant-secondary-color) 15%, white);
    --table-striped-color:var(--tenant-secondary-color);
    --table-active-bg: color-mix(in srgb, var(--tenant-secondary-color) 10%, white);
    --table-active-color: var(--tenant-secondary-color);
    --table-hover-bg: color-mix(in srgb, var(--tenant-secondary-color) 10%, white);
    --table-hover-color: var(--tenant-secondary-color);
    color: var(--table-color);
    border-color: var(--table-border-color);
}

@if $enable-dark-mode {
    @include color-mode(dark, true) {
        .navbar-menu .navbar-nav .nav-link.active {
            color: var(--tenant-primary-color);
            background-color: color-mix(in srgb, var(--tenant-primary-color) 10%, black);
        }
        
        .navbar-menu .navbar-nav .nav-sm .nav-link.active {
            color: var(--tenant-secondary-color);
            background-color: transparent;
        }

        .bg-tenant-primary {
            background-color: color-mix(in srgb, var(--tenant-primary-color) 25%, black);
        }
        
        .bg-tenant-primary-subtle {
            background-color: color-mix(in srgb, var(--tenant-primary-color) 10%, black);
        }
        
        .bg-tenant-secondary {
            background-color: color-mix(in srgb, var(--tenant-secondary-color) 25%, black);
        }
        
        .bg-tenant-secondary-subtle {
            background-color: color-mix(in srgb, var(--tenant-secondary-color) 10%, black);
        }
        
        .text-tenant-primary {
            color: var(--tenant-primary-color);
        }
        
        .text-tenant-primary-subtle {
            color: color-mix(in srgb, var(--tenant-primary-color) 10%, black);
        }
        
        .text-tenant-secondary {
            color: var(--tenant-secondary-color);
        }
        
        .text-tenant-secondary-subtle {
            color: color-mix(in srgb, var(--tenant-secondary-color) 10%, black);
        }
        
        .table-tenant-primary {
            --table-color: var(--tenant-primary-color);
            --table-bg: color-mix(in srgb, var(--tenant-primary-color) 25%, black);
            --table-border-color: var(--tenant-primary-color);
            --table-striped-bg: color-mix(in srgb, var(--tenant-primary-color) 15%, black);
            --table-striped-color:var(--tenant-primary-color);
            --table-active-bg: color-mix(in srgb, var(--tenant-primary-color) 10%, black);
            --table-active-color: var(--tenant-primary-color);
            --table-hover-bg: color-mix(in srgb, var(--tenant-primary-color) 10%, black);
            --table-hover-color: var(--tenant-primary-color);
            color: var(--table-color);
            border-color: var(--table-border-color);
        }
        
        .table-tenant-secondary {
            --table-color: var(--tenant-secondary-color);
            --table-bg: color-mix(in srgb, var(--tenant-secondary-color) 25%, black);
            --table-border-color: var(--tenant-secondary-color);
            --table-striped-bg: color-mix(in srgb, var(--tenant-secondary-color) 15%, black);
            --table-striped-color:var(--tenant-secondary-color);
            --table-active-bg: color-mix(in srgb, var(--tenant-secondary-color) 10%, black);
            --table-active-color: var(--tenant-secondary-color);
            --table-hover-bg: color-mix(in srgb, var(--tenant-secondary-color) 10%, black);
            --table-hover-color: var(--tenant-secondary-color);
            color: var(--table-color);
            border-color: var(--table-border-color);
        }
    }
}